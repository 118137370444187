import './feedback.scss';

function Feedback(props) {
	return (
		<footer className='feedback'>
			This {props.pageType} was made for you!
			<br />
			Help me improve it.
			<br />
			Sending me your feedback on{' '}
			<a target='_blank' href='https://www.instagram.com/epiclootz/'>
				my Instagram
			</a>
			!
		</footer>
	);
}

export default Feedback;
