import * as Articles from 'articles';

import levelData from '../data/generators/potions/level.json';
import effectsData from '../data/generators/potions/effects.json';
import potionData from '../data/generators/potions/potion.json';

import containerData from '../data/generators/potions/container.json';
import containerAdjData from '../data/generators/potions/containerAdjective.json';

import liquidData from '../data/generators/potions/liquid.json';
import liquidAdjData from '../data/generators/potions/liquidAdjective.json';

const generatePotion = function () {
	let potionName = '';

	//name
	let levelIndex = Math.floor(Math.random() * levelData.length);
	let level = levelData[levelIndex];
	potionName = Articles.articlize(level);

	var potionSynonym =
		potionData[Math.floor(Math.random() * potionData.length)];
	potionName += ' ' + potionSynonym;

	potionName += ' of ';

	let effect = effectsData[Math.floor(Math.random() * effectsData.length)];
	potionName += effect.name;

	//effect
	let effectDescription = effect.property;
	effectDescription = effectDescription.replace(
		'{v}',
		effect.scaledValues[levelIndex]
	);

	//description
	let vesselAdj =
		containerAdjData[Math.floor(Math.random() * containerAdjData.length)];
	let vessel =
		containerData[Math.floor(Math.random() * containerData.length)];
	let description = Articles.articlize(vesselAdj) + ' ' + vessel + ' of ';
	let liquidAdj =
		liquidAdjData[Math.floor(Math.random() * liquidAdjData.length)];
	let liquid = liquidData[Math.floor(Math.random() * liquidData.length)];
	description += liquidAdj + ' ' + liquid;

	let potion = {
		name: potionName,
		effect: effectDescription,
		description: description,
	};

	return potion;
};

const totalPossibilities = function () {
	let total = 1;

	total =
		levelData.length *
		effectsData.length *
		containerAdjData.length *
		containerData.length *
		liquidAdjData.length *
		liquidData.length;

	return total.toLocaleString('en-US');
};

export { generatePotion, totalPossibilities };
