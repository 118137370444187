import './notfound.scss';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MimicImg from '../../assets/images/Mimic.webp';

function NotFound(props) {
	const navigate = useNavigate();

	useEffect(() => {}, []);

	return (
		<div className='notfound'>
			<h1>
				Wait a second...
				<br />
				...that's not loot!
			</h1>
			<img src={MimicImg} />
		</div>
	);
}

export default NotFound;
