import './tables.scss';

import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';

import tablesData from '../../data/tables/tables.json';
import Feedback from '../feedback/feedback';

function Table(props) {
	const { tableId } = useParams();
	const [tableMeta, setTableMeta] = useState(null);
	const [tableData, setTableData] = useState(null);
	const [rolledValue, setRolledValue] = useState(0);

	useEffect(() => {
		let metaData = tablesData.find((elm) => {
			return elm.dataFile === tableId;
		});
		setTableMeta(metaData);
		let data = require('../../data/tables/' + metaData.dataFile + '.json');
		setTableData(data);
	}, [tableId]);

	function renderTableRows() {
		return tableData.map((element) => {
			let name = null;
			if (element.name) {
				name = (
					<div>
						<strong>{element.name}</strong>
					</div>
				);
			}
			let rowClass = element.number === rolledValue ? 'highlight' : '';
			return (
				<tr
					id={'row' + element.number}
					key={element.number}
					className={rowClass}
				>
					<td>{element.number}</td>
					<td>
						{name}
						{element.description}
					</td>
				</tr>
			);
		});
	}

	function renderImage() {
		let img = require('../../assets/images/tables/' + tableMeta.imageFile);
		let itemStyle = {
			backgroundImage: `url(${img})`,
			backgroundPositionY: `${tableMeta.imageOffset}`,
		};
		return <div className='tableImage' style={itemStyle}></div>;
	}

	function rollTable() {
		let result = Math.floor(Math.random() * (100 - 1 + 1) + 1);
		setRolledValue(result);
		let elmId = 'row' + result;
		var element = document.getElementById(elmId);
		element.scrollIntoView({ block: 'center' });
		ReactGA.event({
			category: 'Table',
			action: 'Roll: ' + tableMeta.name,
		});
	}

	return (
		tableMeta &&
		tableData && (
			<div className='tablePage'>
				<div className='headWrapper'>
					<div className='textHead'>
						<h1>{tableMeta.name.toLowerCase()}</h1>
						<div className='tableDescription'>
							{tableMeta.description}
						</div>
					</div>
					<div className='imageHead'>{renderImage()}</div>
				</div>
				<div className='tableWrapper'>
					<i>{tableMeta.leadIn}</i>
					<table>
						<thead>
							<tr>
								<th>D100</th>
								<th>{tableMeta.resultHeader.toUpperCase()}</th>
							</tr>
						</thead>
						<tbody>{renderTableRows()}</tbody>
					</table>
				</div>
				<div className='buttonWrapper'>
					<Button className='rollButton' onClick={rollTable}>
						Roll on Table
					</Button>
				</div>

				<Feedback pageType='table' />
			</div>
		)
	);
}

export default Table;
