import './maps.scss';

import mapData from '../../data/maps/maps.json';

import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import FileSaver from 'file-saver';
import LazyLoad from 'react-lazyload';
import ReactGA from 'react-ga4';

function Maps(props) {
	useEffect(() => {}, []);

	function downloadMap(imgId) {
		let imgElm = document.getElementById(imgId);
		let imgSrc = imgElm.src;
		let fileName = imgId + '.png';
		FileSaver.saveAs(imgSrc, fileName);
		ReactGA.event({
			category: 'Map',
			action: 'Download: ' + imgId,
		});
	}

	function getMapList() {
		let sortedData = mapData.sort((a, b) =>
			a.name > b.name ? 1 : b.name > a.name ? -1 : 0
		);
		let mapListItems = sortedData.map((element) => {
			let img = require('../../assets/maps/' + element.preview);
			let imgId = element.name.toLowerCase().replace(' ', '');
			return (
				<div className='mapListItem'>
					<div className='mapName'>
						{element.name}
						<Button onClick={() => downloadMap(imgId)}>
							Download
						</Button>
					</div>
					<div className='imgWrapper'>
						<LazyLoad height={'100%'}>
							<img id={imgId} src={img} />
						</LazyLoad>
					</div>
				</div>
			);
		});
		return mapListItems;
	}

	return (
		<div className='maps'>
			<h1>maps</h1>
			{getMapList()}
		</div>
	);
}

export default Maps;
