import './tables.scss';

import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

import tablesData from '../../data/tables/tables.json';

function Tables(props) {
	const navigate = useNavigate();

	useEffect(() => {}, []);

	function getTables() {
		let sortedTables = tablesData.sort((a, b) =>
			a.name > b.name ? 1 : b.name > a.name ? -1 : 0
		);
		return sortedTables.map((element) => {
			let image = require('../../assets/images/tables/' +
				element.imageFile);
			let itemStyle = {
				backgroundImage: `url(${image})`,
				backgroundPositionY: `${element.imageOffset}`,
			};
			return (
				<div
					key={element.dataFile}
					className='tableItem'
					style={itemStyle}
					onClick={() => tableItemClick(element.dataFile)}
				>
					<div className='tableName'>{element.name}</div>
				</div>
			);
		});
	}

	function tableItemClick(table) {
		navigate('/table/' + table);
	}

	return (
		<div className='tables'>
			<h1>hundo tables</h1>
			<div className='tableList'>{getTables()}</div>
		</div>
	);
}

export default Tables;
