import '../generator.scss';

import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ReactGA from 'react-ga4';

import * as PotionUtil from '../../../util/potion';
import Feedback from '../../feedback/feedback';

function PotionGenerator(props) {
	const [potion, setPotion] = useState(null);
	const [possibilities, setPossibilities] = useState('0');

	useEffect(() => {
		let generatedPot = PotionUtil.generatePotion();
		setPotion(generatedPot);
		let combos = PotionUtil.totalPossibilities();
		setPossibilities(combos);
		ReactGA.event({
			category: 'Generator',
			action: 'Gen: Potion',
		});
	}, []);

	function generateClick(e) {
		let generatedPot = PotionUtil.generatePotion();
		setPotion(generatedPot);
		ReactGA.event({
			category: 'Generator',
			action: 'Gen: Potion',
		});
	}

	function shareClick(e) {
		let resultsToCopy = potion.name.toUpperCase();
		resultsToCopy += '\r\n\r\nepiclootz.fun';
		ReactGA.event({
			category: 'Share',
			action: 'Gen: Epic Character',
		});
		if (navigator.share) {
			navigator.share({
				text: resultsToCopy,
			});
		} else {
			navigator.clipboard.writeText(resultsToCopy);
		}
	}

	return (
		potion && (
			<>
				<div className='description'>potion generator</div>
				<div className='possibilities'>
					{possibilities} possible outcomes
				</div>
				<div className='result' lang='en'>
					{potion.name}
				</div>
				<div className='resultEffect' lang='en'>
					{potion.effect}
				</div>
				<div className='resultDescription' lang='en'>
					{potion.description}
				</div>
				<div className='actions'>
					<Button variant='success' onClick={generateClick}>
						Generate Another!
					</Button>
					<Button variant='primary' onClick={shareClick}>
						Share
					</Button>
				</div>
				<Feedback pageType='generator' />
			</>
		)
	);
}

export default PotionGenerator;
