import '../generator.scss';

import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ReactGA from 'react-ga4';

import * as GenUtil from '../../../util/generators';
import Feedback from '../../feedback/feedback';

function CharacterGenerator(props) {
	const [character, setCharacter] = useState('');
	const [possibilities, setPossibilities] = useState('0');

	const genEx =
		'a totally epic {race} {class} with {cosmeticfeature} {holding} {a:weapon} of {virtue}';

	useEffect(() => {
		let character = GenUtil.translateExpression(genEx);
		setCharacter(character);
		let combos = GenUtil.totalPossibilities(genEx);
		setPossibilities(combos);
		ReactGA.event({
			category: 'Generator',
			action: 'Gen: Epic Character',
		});
	}, []);

	function generateClick(e) {
		let character = GenUtil.translateExpression(genEx);
		setCharacter(character);
		ReactGA.event({
			category: 'Generator',
			action: 'Gen: Epic Character',
		});
	}

	function shareClick(e) {
		let resultsToCopy = character.toUpperCase();
		resultsToCopy += '\r\n\r\nepiclootz.fun';
		ReactGA.event({
			category: 'Share',
			action: 'Gen: Epic Character',
		});
		if (navigator.share) {
			navigator.share({
				text: resultsToCopy,
			});
		} else {
			navigator.clipboard.writeText(resultsToCopy);
		}
	}

	return (
		<>
			<div className='description'>epic character generator</div>
			<div className='possibilities'>
				{possibilities} possible outcomes
			</div>
			<div className='result' lang='en'>
				{character}
			</div>
			<div className='actions'>
				<Button variant='success' onClick={generateClick}>
					Generate Another!
				</Button>
				<Button variant='primary' onClick={shareClick}>
					Share
				</Button>
			</div>
			<Feedback pageType='generator' />
		</>
	);
}

export default CharacterGenerator;
