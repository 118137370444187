import './home.scss';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CharacterGenerator from '../generators/character/character';
import TablesImage from '../../assets/images/home/Tables.webp';
import PotionImage from '../../assets/images/home/Potion.webp';
import MapImage from '../../assets/images/home/Map.webp';
import StoreImage from '../../assets/images/home/STORE.png';
import NewLoot from '../../new.json';

function Home(props) {
	const navigate = useNavigate();

	useEffect(() => {}, []);

	const tableStyle = {
		backgroundImage: `url(${TablesImage})`,
		backgroundPositionY: `-100px`,
	};
	const potionStyle = {
		backgroundImage: `url(${PotionImage})`,
		backgroundPositionY: `0px`,
	};
	const mapStyle = {
		backgroundImage: `url(${MapImage})`,
		backgroundPositionY: `0px`,
	};

	const storeStyle = {
		backgroundImage: `url(${StoreImage})`,
		backgroundPositionY: `0px`,
		backgroundPositionX: `50%`,
		minHeight: `150px`,
		marginBottom: `20px`,
		cursor: `pointer`
	};

	function newClick() {
		navigate(NewLoot.link);
	}

	function getNewLoot() {
		if (NewLoot.text) {
			let expiration = new Date(NewLoot.date);
			expiration.setDate(expiration.getDate() + 7);
			let today = new Date();
			if (today < expiration) {
				return (
					<div className='newWrapper' onClick={newClick}>
						NEW EPIC LOOTZ FOUND : {NewLoot.text}
					</div>
				);
			}
		}
	}

	return (
		<>
			{getNewLoot()}
			<div
				style={storeStyle}
				onClick={() => window.open('https://epic-lootz-store.printify.me/', '_blank')}
			>
			</div>
			<div className='homemenu'>
				<div
					className='menuItem'
					style={potionStyle}
					onClick={() => navigate('/potiongenerator')}
				>
					<div className='menuLabel'>POTION GENERATOR</div>
				</div>
				<div
					className='menuItem'
					style={tableStyle}
					onClick={() => navigate('/tables')}
				>
					<div className='menuLabel'>TABLES</div>
				</div>
				<div
					className='menuItem'
					style={mapStyle}
					onClick={() => navigate('/maps')}
				>
					<div className='menuLabel'>MAPS</div>
				</div>
			</div>
			<CharacterGenerator />
		</>
	);
}

export default Home;
