import './App.scss';

import { useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Head from './components/head/head';
import Foot from './components/foot/foot';
import Home from './components/home/home';
import CharacterGenerator from './components/generators/character/character';
import PotionGenerator from './components/generators/potion/potion';
import Tables from './components/tables/tables';
import Table from './components/tables/table';
import Maps from './components/maps/maps';
import NotFound from './components/notfound/notfound';

function App() {
	//const TRACKING_ID = 'UA-37504888-2';
	const TRACKING_ID = 'G-F54QG7MR8Z';
	ReactGA.initialize(TRACKING_ID);

	let location = useLocation();

	useEffect(() => {
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname + window.location.search,
		});
		//ReactGA.pageview(window.location.pathname + window.location.search);
	}, [location]);

	return (
		<div className='App'>
			<Head />
			<div className='content'>
				<Routes>
					<Route index element={<Home />} />
					<Route
						path='potiongenerator'
						element={<PotionGenerator />}
					/>
					<Route
						path='charactergenerator'
						element={<CharacterGenerator />}
					/>
					<Route path='tables' element={<Tables />} />
					<Route path='table/:tableId' element={<Table />} />
					<Route path='maps' element={<Maps />} />
					<Route path='notfound' element={<NotFound />} />
					<Route
						path='*'
						element={<Navigate to='/notfound' replace />}
					/>
				</Routes>
			</div>
			<Foot />
		</div>
	);
}

export default App;
