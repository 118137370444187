import './head.scss';

import { useEffect, useState } from 'react';
import { List, Instagram, Shop } from 'react-bootstrap-icons';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, Outlet, useNavigate } from 'react-router-dom';

function Head(props) {
	const [showMenu, setShowMenu] = useState(false);
	const navigate = useNavigate();

	function openMenu(e) {
		setShowMenu(true);
	}

	function closeMenu(e) {
		setShowMenu(false);
	}

	function toInstagram() {
		window.open('https://www.instagram.com/epiclootz/', '_blank');
	}

	function toStore() {
		window.open('https://epic-lootz-store.printify.me/', '_blank');
		closeMenu();
	}

	return (
		<>
			<header className='header'>
				<div className='inner'>
					<div className='menu'>
						<List onClick={openMenu} />
					</div>
					<div className='title' onClick={() => navigate('/')}>
						<div className='center'>
							<img
								alt='EpicLootz'
								src={require('../../assets/images/WordLogo.webp')}
							/>
						</div>
					</div>
					<div className='insta'>
						<Shop onClick={toStore} />
						<Instagram onClick={toInstagram} />
					</div>
				</div>
			</header>

			<Offcanvas show={showMenu} onHide={closeMenu}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>EPIC MENU</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Link
						className='menuItem'
						to='/charactergenerator'
						onClick={closeMenu}
					>
						epic character generator
					</Link>
					<Link
						className='menuItem'
						to='/potiongenerator'
						onClick={closeMenu}
					>
						potion generator
					</Link>
					<Link className='menuItem' to='/tables' onClick={closeMenu}>
						hundo tables
					</Link>
					<Link className='menuItem' to='/maps' onClick={closeMenu}>
						maps
					</Link>
					<Link className='menuItem' onClick={toStore}>
						store
					</Link>
				</Offcanvas.Body>
			</Offcanvas>

			<Outlet />
		</>
	);
}

export default Head;
